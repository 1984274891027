
@import "../node_modules/@hi-interactive-admin/colliers-b2c/index.esm.css";
@import "swiper/swiper-bundle.min.css";

*{
    margin:0;
    padding:0;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.add-dashboard-btn{
      position: absolute !important;
      right: 0px;
      top: 48px;
}
.page-name{
  text-align: center;
  text-transform: uppercase;
  font-size: 19px !important;
}
.css-1jsm74t-MuiButton-startIcon {
  display: inherit !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
button.MuiTab-root {
  border-right: 1px solid #25408f !important;
}
button.MuiTab-root {
  line-height: 60px !important;
  padding: 0px 20px;
}
.css-1rqs9nz-MuiButtonBase-root-MuiButton-root.MuiButton-contained:hover {
  background-color: #000759 !important;
  color: #fff !important;
}
.css-1rqs9nz-MuiButtonBase-root-MuiButton-root.MuiButton-contained {
  background-color: #000759 !important;
  color: #fff !important;
}
.css-1m8l5ic-MuiButtonBase-root-MuiButton-root.MuiButton-contained {
  background-color: #000759 !important;
  color: #fff;
}

#main{
  padding-bottom: 50px;
}











.css-1gy9hnz-MuiButtonBase-root-MuiButton-root.MuiButton-outlined {
  border-radius: 5px 0px 0px 5px !important;
}
.css-1m8l5ic-MuiButtonBase-root-MuiButton-root.MuiButton-contained {
  border-radius: 0px 5px 5px 0px !important;
}

.css-1v3iw7m {
  background-color: #edf2ff;
}
/* .css-1v3iw7m {
  padding-top: 7px;
  padding-bottom: 7px;
} */
.css-o8fuj9-MuiTypography-root {
  color: #25408f !important;
}
.css-f4ayyb {
  margin: 0px !important;
  margin-bottom: 15px !important;
}
.out-border {
  border: 1px solid #25408f !important;
  border-radius: 5px;
  min-height: 41px !important;
  height: 23px !important;
}

/* .css-sw25g4-MuiTabs-root:last-child {
  border-right: none !important;
} */

.css-aixiz9-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px 7px !important;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 17px !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 17px !important;
}
.css-1r9xrk5 {
  margin: 15px !important;
  margin-top: 0px !important;
}
.css-lohd6h-MuiSvgIcon-root-MuiSelect-icon.Mui-disabled {
  right: -25px !important;
  top: 15px !important;
  font-size: 22px !important;
}
/* [class*=MuiTableContainer-root] [class*=MuiTable-root] [class*=MuiTableBody] [class*=MuiTableRow-root]:nth-child(odd) {
  background-color: #fff !important;
} */
span.MuiCheckbox-root:before {
  height: 17px !important;
  width: 17px !important;
}
.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15px !important;
} 
.css-19x5exo {
  padding: 24px 24px 1px !important;
}
.MuiInputBase-input {
  text-align: left;
}
.css-1i5b2bs {
  margin-bottom: 10px !important;
  margin-top: 32px !important;
}
.css-gprtzg-MuiFormControl-root {
  max-width: 250px !important;
}

.css-1l48r2-MuiPaper-root-MuiTableContainer-root{
  --Paper-shadow: none !important;
}
.css-1j74uew-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}
.css-16s7o2u {
  justify-content: center !important;
}
.footer-container{
  position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
}
.css-1wdt58b {
  border: 1px solid #dbe5ff !important;

}

.css-1yb42bw-MuiPaper-root-MuiTableContainer-root {
  --Paper-shadow: none !important;
}
.css-j2sslj-MuiGrid-root {
  border-color: #dbe5ff !important;
}
.MuiTextField-root .MuiInputBase-root {
  background-color: transparent !important;
}
[class*=MuiFormControl-root] {
  background-color: transparent !important;
  font-family: var(--font-family);
}
.MuiTextField-root {
  margin-bottom: 0 !important;
}
.css-1gu6zxq-MuiTableRow-root {
  border-color: transparent !important;
}
.css-1nowbqt-MuiInputAdornment-root {
display: none !important;
}
.css-1orzuox-MuiTableCell-root {
  font-family: "Open Sans" !important;
  font-size: 16px !important;
}
.css-1orzuox-MuiTableCell-root {
  color: #000759 !important;
}
/* .css-1umw9bq-MuiSvgIcon-root {
  fill: rgb(141 144 152) !important;
} */
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  /* width: 100% !important; */
  width: 352px !important;
  margin-right: 20px;
}
.css-65syq0-MuiButtonBase-root-MuiButton-root.MuiButton-outlined {
  color: #000759 !important;
  border-color: #1976d2;
}
.css-xn1sub-MuiButtonBase-root-MuiButton-root.MuiButton-outlined {
  color: #000759 !important;
  border-color: #1976d2;
}

.power-bi-report-class{
  height:calc(100vh - 121px);
}

/* .css-1fr578y-MuiTabs-scroller {
  border-left: 1px solid;
} */
button.MuiTab-root {
  border-left: 1px solid #25408f !important;
}
.single-line{
  display: flex !important;
}


.css-sw25g4-MuiTabs-root {
  height: 40px !important;
  min-height: 40px !important;
}

button.MuiTab-root.Mui-selected:after {
  bottom: 9px !important;
}
.tit-a{
  font-size: 16px !important;
  margin-bottom: 20px !important;
  text-transform: uppercase;
}

.list-grid{
  background-color: #000759 !important;
  color: #fff !important;
}
.bor-radi{
  border-radius: 0px !important;
}
.css-15gj1iq-MuiButtonBase-root-MuiButton-root.MuiButton-contained {
  background-color: #000759 !important;
}
.css-1qsqbiz-MuiButtonBase-root-MuiButton-root.MuiButton-contained {
  background-color: #000759 !important;
}
.css-j2sslj-MuiGrid-root {
  width: calc(100% + 88px) !important;
}
 .succ-m {
  background: #58a49e !important;
}
.err-m{
background-color: #ed1b34	!important;
}
.cli-dash{
  width: 290px !important;
}
.css-1uj8odg-MuiPaper-root-MuiTableContainer-root {
  border: 1px solid #dbe5ff !important;
  --Paper-shadow: none !important;
}
.table-rec{
  padding-top: 17px;
}
.cli-dash-log{
  max-width: 100% !important;
}
.display-rec-4{
  height: calc(100vh - 270px);
  overflow: auto;
}
.css-1gu6zxq-MuiTableRow-root {
  border-bottom: 2px solid #000;
  border-color: #1976d2;
  border-bottom: 1px !important;
}
.user-name{
  cursor: pointer;
}
/* .space-btn{
  padding: 0px 9px 0px 5px !important;
  margin: 0px !important;
} */
/* .css-1umw9bq-MuiSvgIcon-root {
  fill: rgb(37 64 143) !important;
} */
.box-name-tit{
  font-size: 16px !important;
  margin-bottom: 0px !important;
}
.t-heading-bor{
  border-bottom: 1px solid #1976d2 !important;
}
.css-1orzuox-MuiTableCell-root {
  border-bottom: 0px !important;
  font-size: 14px !important;
}
.css-1dc80h3-MuiTableCell-root {
  font-size: 14px !important;
}
.MuiModal-root .MuiBox-root {
  box-shadow: none;
}
.css-1a7iywq-MuiTableHead-root {
  display: table-header-group;
  border-bottom: 1px solid #1976d2 !important;
}
.css-1n5fv7-MuiGrid-root>.MuiGrid-item {
  padding-left: 0 !important;
}
label{
  font-size: 13px !important;
}
.site-border{
  border: 1px solid #dbe5ff !important;
}
.css-1px5beb-MuiFormControl-root-MuiTextField-root {
  padding-top: 0px !important;
}
.cack-to-dash{
  cursor: pointer;
}
.css-si86to-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0px 8px !important;
}
input::placeholder {
  font-size: 14px;
}
.dash-det{
  margin-bottom: 17px !important;
}
.list-height{
  height: calc(100vh - 360px);
  overflow: auto;
  overflow-x: hidden;
}
.list-height-log{
  height: calc(100vh - 450px);
  overflow: auto;
  overflow-x: hidden;
}
.list-height-aud{
  height: calc(100vh - 445px);
  overflow: auto;
  overflow-x: hidden;
}
.cus-label-date{
  font-weight: 600 !important;
    text-transform: uppercase;
    font-size: 13px !important;
}
td{
  font-size: 14px !important;
}
.btn-center{
  justify-content: center;
}
.footer-link{
  font-size: 14px !important;
}
.space-lab{
padding-top: 10px !important;
width: 100% !important;
}
.css-1s63ceu {
    display: inherit !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
button.MuiTab-root.Mui-selected {
  color: #000759;
  color: #fff;
  text-shadow: .25px 0 0 #000759;
  text-shadow: .25px 0 0 var(--color-primary);
  background: #000759;
}
button.MuiTab-root.Mui-selected:active, button.MuiTab-root.Mui-selected:focus {
  background-color: #000759 !important;
  border: none !important;
  color: #fff !important;
}
.select-rights .MuiSelect-icon {
  right: 5px !important;
}
.MuiInputAdornment-root {
  margin-left: 0;
  margin-right: 0;
  padding-left: 8px;
  padding-left: 0px !important;
  padding-right: -8px;
  width: 0px;
  margin-right: 0px !important;
}
.MuiTextField-root .MuiInputBase-root .MuiInputBase-input {
  margin-top: var(--space-s);
  padding: 0;
  font-size: 14px;
}
/* .MuiInputBase-root .MuiSelect-select {
  padding: 0px 0px 0px 8px !important;
} */
.bor-radi{
  width: 15px !important;
  /* max-width: 22px !important; */
  min-width: 15px !important;
}
.add-dashb-d, .add-dashb-e{
  cursor: pointer;
}
.add-dashb-d:hover{
  color: red;
}
.add-dashb-e:hover{
  color: green;
}
.MuiPaper-root {
 box-shadow: none !important;
}
.MuiMenuItem-root:hover {
  background-color: #25408f !important;
}
.Mui-selected {
  background-color: #000759 !important;
}
.MuiInputBase-root .MuiSelect-select {
color: #000759 !important;
}
.MuiTextField-root .MuiInputBase-root .MuiInputBase-input {
  padding-bottom: 3px !important;
}
/* .MuiFormControl-root .MuiInputBase-root.MuiInputBase-adornedStart svg {
  position: absolute !important;
  right: 0 !important;
} */
.MuiSvgIcon-root.MuiSelect-icon {
  right: -5px !important;
}
.dashboard-search-icon{
  position: absolute !important;
  right: 0 !important;
  background: #fff;
  padding: 0px 2px;
}